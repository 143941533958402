/**
 * @file Index - Header
 */

import React from 'react';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import Links from '../Links';
import Logout from '../Logout';

const Header = ({ identity }) => (
  <Headroom>
    <header className="header">
      <div className="grid-container">
        <div className="grid-x">
          <div className="cell large-2">
            <Link to="/app/dashboard">
              <div className="header__logo" />
            </Link>
          </div>
          <div className="cell large-10">
            <nav>
              <ul className="header__list">
                <Links collection="main" prefix="header" />
                <li className="header__list-item">
                  <Logout identity={identity} />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </Headroom>
);

export default Header;
