/**
 * @file Integrations Page
 */

import React from 'react';
import Layout from '../components/Layout';
import IntegrationLinks from '../components/IntegrationLinks';

const IntegrationsPage = () => (
  <Layout>
    <div className="grid-x grid-padding-x margin-bottom-40">
      <div className="cell large-12">
        <h1 className="heading heading--underline-large">
          Integrations
        </h1>
      </div>
    </div>
    <div className="grid-x grid-padding-x">
      <div className="cell large-12">
        <IntegrationLinks />
      </div>
    </div>
  </Layout>
);

export default IntegrationsPage;
