/**
 * @file Video
 */

import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ src }) => (
  <video controls className="video">
    <source src={src} type="video/mp4" />
  </video>
);

Video.propTypes = {
  src: PropTypes.string,
};

Video.defaultProps = {
  src: null,
};

export default Video;
