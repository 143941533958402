/**
 * @file Downloads Page
 */

import React from 'react';
import Layout from '../components/Layout';

const DownloadsPage = () => (
  <Layout>
    <div className="grid-x grid-padding-x margin-bottom-40">
      <div className="cell large-12">
        <h1 className="heading heading--underline-large">
          Downloads
        </h1>
      </div>
    </div>
    <div className="grid-x grid-padding-x">
      <div className="cell large-3">
        <div className="callout callout--primary padding-top-30">
          <div className="callout__image callout__image--chrome callout__image--muted margin-bottom-30" />
          <h6 className="callout__heading">
            Chrome Extension
          </h6>
          <p className="callout__text">
            Version: 3.0.2
          </p>
          <a
            href="https://chrome.google.com/webstore/detail/johns-mustang/onlbfcdbebfmjmoiefikpecmkaincakc?hl=en&authuser=3"
            target="_blank"
            rel="noreferrer"
            className="callout__button button button--expanded button--primary"
          >
            Download
          </a>
        </div>
      </div>
      <div className="cell large-3">
        <div className="callout callout--primary padding-top-30">
          <div className="callout__image callout__image--logo margin-top-20 margin-bottom-30" />
          <h6 className="callout__heading">
            Logo
          </h6>
          <p className="callout__text">
            Version: 1.0
          </p>
          <a
            href="https://github.com/johnsmustang/logo/releases/tag/v1.0"
            target="_blank"
            rel="noreferrer"
            className="callout__button button button--expanded button--primary"
          >
            Download
          </a>
        </div>
      </div>
      <div className="cell large-3">
        <div className="callout callout--primary padding-top-30">
          <div className="callout__image callout__image--manufacturers margin-top-20 margin-bottom-30" />
          <h6 className="callout__heading">
            Manufacturer Logos
          </h6>
          <p className="callout__text">
            Version: 1.1
          </p>
          <a
            href="https://github.com/johnsmustang/manufacturer-logos/releases/tag/v1.1"
            target="_blank"
            rel="noreferrer"
            className="callout__button button--expanded button button--primary"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default DownloadsPage;
