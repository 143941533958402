/**
 * @file Hr
 */

import React from 'react';

const Hr = () => (
  <hr className="hr" />
);

export default Hr;
