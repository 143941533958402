/**
 * @file Layout
 */

import React from 'react';
import Helmet from 'react-helmet';

import { useIdentityContext } from 'react-netlify-identity-widget';
import Chrome from '../Chrome';
import Header from '../Header';
import Footer from '../Footer';

import 'prismjs/themes/prism-tomorrow.css';
import '../../scss/app.scss';

const Layout = ({ children }) => {
  const identity = useIdentityContext();

  return (
    <>
      <Chrome
        header={<Header identity={identity} />}
        footer={<Footer />}
      >

        <Helmet
          title="John's Mustang Dashboard"
          meta={[
            { name: 'description', content: 'John\'s Mustang Dashboard' },
          ]}
        />

        {children}

      </Chrome>
    </>
  );
};

export default Layout;
