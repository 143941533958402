/**
 * @file Docs Page
 */

import React from 'react';
import { Link } from 'gatsby';
import DocLayout from '../components/DocLayout';

const DocsPage = () => (
  <DocLayout heading="Docs for John's Mustang">
    <p className="doc__subheading">
      <Link to="/app/docs/shopify">
        Shopify
      </Link>
    </p>
  </DocLayout>
);

export default DocsPage;
