/**
 * @file Routes
 */

import Dashboard from '../pages/Dashboard';
import Docs from '../pages/Docs';
import Integrations from '../pages/Integrations';
import Downloads from '../pages/Downloads';
import StyleGuide from '../pages/StyleGuide';
import Support from '../pages/Support';
import Shopify from '../docs/shopify';
import Sample from '../docs/sample';

const routes = {
  main: [
    {
      path: '/dashboard',
      component: Dashboard,
      name: 'Home',
    },
    {
      path: '/docs',
      component: Docs,
      name: 'Docs',
    },
    {
      path: '/integrations',
      component: Integrations,
      name: 'Integrations',
    },
    {
      path: '/downloads',
      component: Downloads,
      name: 'Downloads',
    },
    {
      path: '/style-guide',
      component: StyleGuide,
      name: 'Style Guide',
      visible: false,
    },
    {
      path: '/support',
      component: Support,
      name: 'Support',
    },
  ],
  docs: [
    {
      type: 'general',
      path: '/docs/shopify',
      component: Shopify,
      name: 'Shopify',
    },
    {
      type: 'general',
      path: '/docs/sample',
      component: Sample,
      name: 'Sample',
    },
  ],
};

export default routes;
