/**
 * @file Integration Links
 */

import React from 'react';

const IntegrationLinks = () => (
  <div className="grid-x grid-padding-x">
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="Shopify" className="callout__link" href="https://accounts.shopify.com/store-login" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--shopify" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="ShipStation" className="callout__link" href="https://ss3.shipstation.com/" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--shipstation" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="Finale" className="callout__link" href="https://app.finaleinventory.com/johnsmustang/sc2/" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--finale" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="Findify" className="callout__link" href="https://dashboard.findify.io/" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--findify" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="Linode" className="callout__link" href="https://login.linode.com/login" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--linode" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="Netlify" className="callout__link" href="https://app.netlify.com/" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--netlify" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="GitHub" className="callout__link" href="https://github.com/johnsmustang" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--github" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="Avalara" className="callout__link" href="https://identity.avalara.com/account/login" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--avalara" />
      </div>
    </div>
    <div className="cell large-offset-3 large-3">
      <div className="callout callout--primary">
        <a aria-label="QuickBooks" className="callout__link" href="https://quickbooks.intuit.com/login/" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--quickbooks" />
      </div>
    </div>
    <div className="cell large-3">
      <div className="callout callout--primary">
        <a aria-label="TSheets" className="callout__link" href="https://johnsmustang.tsheets.com" target="_blank" rel="noreferrer" />
        <div className="callout__image callout__image--tsheets" />
      </div>
    </div>
  </div>
);

export default IntegrationLinks;
