/**
 * @file Chrome
 */

import React from 'react';
import PropTypes from 'prop-types';

const Chrome = ({ children, header, footer }) => (
  <div>

    {header}

    <main>
      <div className="grid-container">

        {children}

      </div>
    </main>

    {footer}

  </div>
);

Chrome.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};

Chrome.defaultProps = {
  children: null,
  header: null,
};

export default Chrome;
