/**
 * @file Sample
 */

import React from 'react';
import { Link } from 'gatsby';
import Blockquote from '../../components/Blockquote';
import DocLayout from '../../components/DocLayout';
import Heading from '../../components/Heading';
import Hr from '../../components/Hr';
import Image from '../../components/Image';
import Video from '../../components/Video';

import ExampleImage from './images/example.jpg';
import ExampleVideo from './videos/example.mp4';

const Sample = () => (
  <DocLayout heading="Sample">

    <p className="doc__subheading">
      <Link to="#video">Video</Link>
    </p>

    <Heading text="Headings" size="2" underline />

    <Heading text="h2" size="2" />
    <Heading text="h3" size="3" />
    <Heading text="h4" size="4" />
    <Heading text="h5" size="5" />
    <Heading text="h6" size="6" />

    <Heading text="h2 underlined" size="2" underline />
    <Heading text="h3 underlined" size="3" underline />
    <Heading text="h4 underlined" size="4" underline />
    <Heading text="h5 underlined" size="5" underline />
    <Heading text="h6 underlined" size="6" underline />

    <Heading text="Paragraphs" size="2" underline />

    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
      in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident,
      sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
      in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident,
      sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>

    <Heading text="Emphasis" size="2" underline />

    <p>
      <strong>Strong</strong>
    </p>

    <p>
      <i>Italic</i>
    </p>

    <p>
      <strong>
        <i>Strong italic</i>
      </strong>
    </p>

    <p>
      <del>
        Strikethrough
      </del>
    </p>

    <Heading text="Link" size="2" underline />

    <p>
      <Link to="/app/docs">
        Link
      </Link>
    </p>

    <Heading text="Lists" size="2" underline />

    <Heading text="Unordered List" size="5" />

    <ul>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
    </ul>

    <Heading text="Ordered List" size="5" />

    <ol>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
    </ol>

    <Heading text="Blockquote" size="2" underline />

    <Heading text="Default" size="5" />

    <Blockquote>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
    </Blockquote>

    <Heading text="Alert" size="5" />

    <Blockquote type="alert">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
    </Blockquote>

    <Heading text="Warning" size="5" />

    <Blockquote type="warning">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
    </Blockquote>

    <Heading text="Success" size="5" />

    <Blockquote type="success">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
    </Blockquote>

    <Heading text="Horizontal Rule" size="2" underline />

    <Hr />

    <Heading text="Image" size="2" underline />

    <Image src={ExampleImage} />

    <div id="video"><Heading text="Video" size="2" underline /></div>

    <Video src={ExampleVideo} />

    <Heading text="Table" size="2" underline />

    <table className="table">
      <thead>
        <tr>
          <th>Heading</th>
          <th>Heading</th>
          <th>Heading</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
        <tr>
          <td>Cell</td>
          <td>Cell</td>
          <td>Cell</td>
        </tr>
      </tbody>
    </table>

  </DocLayout>
);

export default Sample;
