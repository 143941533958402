/**
 * @file Image
 */

import React from 'react';

const Image = ({ src }) => (
  <>
    <img alt={src} className="image" src={src} />
  </>
);

export default Image;
