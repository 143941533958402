/**
 * @file Support Page
 */

import React from 'react';
import axios from 'axios';
import Layout from '../components/Layout';

class SupportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonClass: null,
      email: '',
      integration: '',
      issue: '',
      name: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      buttonClass: 'loading',
    });

    const {
      email,
      integration,
      issue,
      name,
    } = this.state;

    axios.post('/.netlify/functions/create-github-issue', {
      email,
      integration,
      issue,
      name,
    })

      .then(() => {
        setTimeout(() => {
          this.setState({
            buttonClass: 'success',
          });

          setTimeout(() => {
            this.setState({
              buttonClass: null,
              email: '',
              integration: '',
              issue: '',
              name: '',
            });
          }, 2000);
        }, 4000);
      })
      .catch(() => {
        this.setState({
          buttonClass: null,
        });
      });
  }

  render() {
    const {
      buttonClass,
      email,
      integration,
      issue,
      name,
    } = this.state;

    return (
      <Layout>
        <div className="grid-x grid-padding-x margin-bottom-40">
          <div className="cell large-12">
            <h1 className="heading heading--underline-large">Support</h1>
          </div>
        </div>
        <form onSubmit={this.handleSubmit} action="/">
          <div className="grid-x">
            <div className="medium-6 cell">
              <label className="input-label" htmlFor="name">
                Name
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={this.handleInputChange}
                  className="input"
                  required
                />
              </label>
              <label className="input-label" htmlFor="email">
                Email
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleInputChange}
                  className="input"
                  required
                />
              </label>
              <label className="input-label" htmlFor="integration">
                Integration
                <select
                  id="integration"
                  name="integration"
                  value={integration}
                  onChange={this.handleInputChange}
                  className="select"
                  required
                >
                  <option value="" defaultValue>Select Integration</option>
                  <option value="Chrome Extension">Chrome Extension</option>
                  <option value="Finale">Finale</option>
                  <option value="Findify">Findify</option>
                  <option value="QuickBooks">QuickBooks</option>
                  <option value="ShipStation">ShipStation</option>
                  <option value="Shopify">Shopify</option>
                </select>
              </label>
            </div>
            <div className="medium-6 cell">
              <div className="grid-x grid-padding-x margin-bottom-40">
                <div className="large-12 cell" />
              </div>
            </div>
            <div className="medium-12 cell">
              <label className="input-label" htmlFor="issue">
                Issue
                <textarea
                  id="issue"
                  name="issue"
                  rows="8"
                  placeholder="Please describe in as much detail as possible what the issue is..."
                  value={issue}
                  onChange={this.handleInputChange}
                  className="textarea"
                  required
                />
              </label>
            </div>
            <div className="medium-12 cell">
              <button
                type="submit"
                className={`button button--primary button--expanded ${buttonClass ? `button--${buttonClass}` : null}`}
                onClick={this.handleClick}
              >
                {buttonClass === 'success' ? 'Support Ticket Submitted!' : 'Submit Issue'}
              </button>
            </div>
          </div>
        </form>
      </Layout>
    );
  }
}

export default SupportPage;
