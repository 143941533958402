/**
 * @file Doc Layout
 */

import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../Layout';
import Links from '../Links';
import Sidebar from '../Sidebar';

const DocLayout = (props) => {
  const {
    children,
    heading,
    subheading,
  } = props;

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'doc',
        }}
      />
      <Layout>
        <div className="grid-x grid-padding-x">
          <div className="cell large-3">
            <Sidebar>
              <p className="sidebar__heading">
                <Link to="/app/docs">
                  General
                </Link>
              </p>
              <ul className="sidebar__list">
                <Links collection="docs" type="general" prefix="sidebar" />
              </ul>
            </Sidebar>
          </div>
          <div className="cell large-9">
            <header className="doc__header">
              <h1 className="doc__heading">
                {heading}
              </h1>
              <p className="doc__subheading">
                {subheading}
              </p>
            </header>
            {children}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DocLayout;
