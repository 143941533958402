/**
 * @file Heading
 */

import React from 'react';

const Heading = (props) => {
  const {
    size,
    text,
    underline,
  } = props;

  return (
    React.createElement(`h${size}`, { className: underline ? 'heading heading--underline' : 'heading' }, text)
  );
};

export default Heading;
