/**
 * @file Shopify
 */

import React from 'react';
import { Link } from 'gatsby';
import Blockquote from '../../components/Blockquote';
import DocLayout from '../../components/DocLayout';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Video from '../../components/Video';

import AcceptPaymentImage from './images/orders/create/acceptPayment.jpg';
import AddToCartImage from './images/orders/create/addToCart.png';
import ChangeQuantityImage from './images/orders/create/changeQuantity.png';
import ClickItemImage from './images/orders/create/clickItem.png';
import CreateCustomerImage from './images/orders/create/createCustomer.png';
import CreateCustomerFillImage from './images/orders/create/createCustomerFill.jpg';
import EmailInvoiceImage from './images/orders/create/emailInvoice.jpg';
import FindCustomerImage from './images/orders/create/findCustomer.jpg';
import MarkPaidImage from './images/orders/create/markPaid.png';
import MarkPendingImage from './images/orders/create/markPending.png';
import PayCreditImage from './images/orders/create/payCredit.jpg';
import PrintPicklistImage from './images/orders/create/printPicklist.png';
import ProductMatchesImage from './images/orders/create/productMatches.png';
import ProductMatchClickImage from './images/orders/create/productMatchClick.png';
import ProductSearchImage from './images/orders/create/productSearch.png';
import SearchTermImage from './images/orders/create/searchTerm.png';
import SendInvoiceImage from './images/orders/create/sendInvoice.jpg';
import ViewCartImage from './images/orders/create/viewCart.png';
import WebsiteSearchImage from './images/orders/create/websiteSearch.png';

import FindItemVideo from './videos/orders/create/findItem.mp4';

const Shopify = () => (
  <DocLayout heading="Shopify">

    <Blockquote>
      <p className="doc__subheading">
        <Link to="#orders">Orders</Link>
      </p>

      <ul>
        <li type="none">
          <Link to="#createorder">Creating an Order</Link>
        </li>
      </ul>
    </Blockquote>

    <div id="orders">
      <Heading text="Orders" size="2" underline />
    </div>

    <div id="createorder">
      <Heading text="Creating an Order" size="3" />
    </div>

    <Blockquote type="alert">
      <p>
        <strong>IMPORTANT</strong>: Before creating an order, make sure you are logged into
        your Shopify account <a href="https://johnsmustangparts.myshopify.com/admin" target="_blank" rel="noopener noreferrer">here</a>.
      </p>
    </Blockquote>

    <ol>
      <li>
        Go to the John's Mustang website: <a href="https://www.johnsmustang.com" target="_blank" rel="noopener noreferrer">https://www.johnsmustang.com</a>
      </li>
      <li>
        Find the search bar near top of the page.
        <Image src={WebsiteSearchImage} />
      </li>
      <li>
        Enter search term (either an item's name or item's SKU/product number)
        <Image src={SearchTermImage} />
        <ol type="a">
          <li>
            If item appears in Product Matches.
            <Image src={ProductMatchesImage} />
            <ol type="i">
              <li>
                Click the item
                <Image src={ProductMatchClickImage} />
              </li>
            </ol>
          </li>
          <li>
            If item does not appear in Product Matches
            <ol type="i">
              <li>
                Click enter or the magnifying glass
                <Image src={ProductSearchImage} />
              </li>
              <li>
                Find item
                <Video src={FindItemVideo} />
              </li>
              <li>
                Click the item
                <Image src={ClickItemImage} />
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        Click the "ADD TO CART" button
        <Image src={AddToCartImage} />
      </li>
      <li>Repeat steps 2-4 as necessary</li>
      <li>
        Click the "Print Picklist" button (Located in bottom right corner)
        <Image src={PrintPicklistImage} />
      </li>
      <li>Make sure it is pointed to correct printer and then click &#39;Print&#39;</li>
      <li>
        Use picklist to ensure order correctness
        <ol type="a">
          <li>
            If all items are in stock
            <ol type="i">
              <li>
                Does the customer want to add more items?
                <ol>
                  <li>If yes, repeat steps 2-5</li>
                  <li>If no, no changes need to be made</li>
                </ol>
              </li>
              <li>
                <div id="removeitem">
                  Does the customer want to remove items?
                </div>
                <ol>
                  <li>
                    If yes
                    <ol type="a">
                      <li>
                        If not already on Shopping Cart, hover over shopping
                        cart near top right corner
                      </li>
                      <li>
                        Click &#39;VIEW CART&#39;
                        <Image src={ViewCartImage} />
                      </li>
                      <li>
                        Either Click the X next to item(s) you are trying to remove
                        or change the quantity by clicking the &#39;-&#39; or &#39;+&#39; button
                        <Image src={ChangeQuantityImage} />
                      </li>
                    </ol>
                  </li>
                  <li>If no, no changes need to be made</li>
                </ol>
              </li>
            </ol>
            If the answer to both questions was no, proceed to Step 9. Otherwise, repeat Steps 6-8.
          </li>
          <li>
            If all items are not in stock
            <ol type="i">
              <li>
                Does the customer want to backorder item(s)?
                <ol>
                  <li>If yes, no changes need to be made</li>
                  <li>
                    If no, treat as if customer wants to remove item(s) and refer
                    to&nbsp;
                    <Link to="#removeitem">Step 8.a.ii.</Link>
                  </li>
                </ol>
              </li>
              <li>
                Does the customer want to add more items?
                <ol>
                  <li>If yes, repeat steps 2-5</li>
                  <li>If no, no changes need to be made</li>
                </ol>
              </li>
              <li>
                Does the customer want to remove items?
                <ol>
                  <li>
                    If yes, refer to&nbsp;
                    <Link to="#removeitem">Step 8.a.ii.</Link>
                  </li>
                  <li>If no, no changes need to be made</li>
                </ol>
              </li>
            </ol>
            If the answer to question i was yes and ii and iii were no, proceed to
            Step 9. Otherwise, repeat steps 6-8.
          </li>
        </ol>
      </li>
      <li>
        Click &#39;Create Draft Order&#39;
        <br />
        <strong>
          *NOTE: Button will automatically redirect to Shopify page
        </strong>
      </li>
      <li>
        Add customer to order
        <br />
        <strong>
          *STEP IS ESSENTIAL, Customer cannot be added after payment is accepted
        </strong>
        <ol type="a">
          <li>
            Under &#39;Find or create a customer&#39;, enter a search term (either name or phone #)
            <Image src={FindCustomerImage} />
            <ol type="i">
              <li>If the customer is found, click on the name</li>
              <li>
                If the customer is not found, click on &#39;Create a new customer&#39;
                <Image src={CreateCustomerImage} />
                <Image src={CreateCustomerFillImage} />
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        Email Invoice or Accept Payment
        <ol type="a">
          <li>
            Email Invoice emails the invoice to the customer and provides them the option of paying
            through the website. (Good for customers who like to pay through PayPal)
            <Image src={EmailInvoiceImage} />
            <Image src={SendInvoiceImage} />
          </li>
          <li>
            Accept Payment has three options: &#39;Mark as paid&#39;, &#39;Mark as pending&#39;, and
            &#39;Pay with credit card&#39;
            <Image src={AcceptPaymentImage} />
            <ol type="i">
              <li>
                Mark as paid
                <Image src={MarkPaidImage} />
                <br />
                <em>
                  We accept the following:
                </em>
                <ul>
                  <li>Cash Payment</li>
                  <li>Money Order</li>
                </ul>
              </li>
              <li>
                Mark as pending (Only Certain Customers)
                <Image src={MarkPendingImage} />
                <br />
                <em>
                  We accept the following:
                </em>
                <ul>
                  <li>Check</li>
                  <li>Wire Transfer</li>
                </ul>
              </li>
              <li>
                Pay with credit card
                <Image src={PayCreditImage} />
                <br />
                <em>
                  We accept the following:
                </em>
                <ul>
                  <li>American Express</li>
                  <li>Debit card</li>
                  <li>Discover</li>
                  <li>Master Card</li>
                  <li>Visa</li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
  </DocLayout>
);

export default Shopify;
