/**
 * @file Blockquote
 */

import React from 'react';

const Blockquote = (props) => {
  const {
    children,
    type,
  } = props;

  return (
    <blockquote className={`blockquote ${type ? `blockquote--${type}` : ''}`}>
      { children }
    </blockquote>
  );
};

export default Blockquote;
