/**
 * @file Sidebar
 */

import React from 'react';
import SearchBox from './SearchBox';

const Sidebar = ({ children }) => (
  <div className="sidebar">
    {process.env.DOCSEARCH_API_KEY && process.env.DOCSEARCH_INDEX && (
      <SearchBox />
    )}
    {children}
  </div>
);

export default Sidebar;
