/**
 * @file Links
 */

import React from 'react';
import { Link } from 'gatsby';
import routes from '../../config/routes';

class Links extends React.Component {
  constructor(props) {
    super(props);

    ({
      collection: this.collection,
      prefix: this.prefix,
      type: this.type,
    } = props);
  }

  render() {
    return (
      <>
        {routes[this.collection].map((route) => {
          const link = (
            <li className={`${this.prefix}__list-item`} key={route.name}>
              <Link to={`/app${route.path}`} activeClassName={`${this.prefix}__link--active`} className={`${this.prefix}__link`}>
                {route.name}
              </Link>
            </li>
          );

          if (route.visible !== false) {
            if (this.type) {
              if (route.type === this.type) {
                return link;
              }
            } else {
              return link;
            }
          }

          return null;
        })}
      </>
    );
  }
}

export default Links;
