/**
 * @file Footer
 */

import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="footer">
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <div className="cell large-2">
          <Link to="/">
            <div className="footer__logo" />
          </Link>
        </div>
        <div className="cell large-6">
          <ul className="footer__list">
            <li>
              <Link to="/app/docs" className="footer__link">
                Docs
              </Link>
            </li>
            <li>
              <Link to="/app/support" className="footer__link">
                Support
              </Link>
            </li>
            <li>
              <Link to="/app/style-guide" className="footer__link">
                Style Guide
              </Link>
            </li>
            <li>
              <Link to="/app/downloads" className="footer__link">
                Downloads
              </Link>
            </li>
          </ul>
        </div>
        <div className="cell large-4">
          <ul className="footer__social">
            <li>
              <a href="https://github.com/johnsmustang" target="_blank" rel="noreferrer">
                <i className="footer__social-icon social__icon icon-github" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/johnsmustang" target="_blank" rel="noreferrer">
                <i className="footer__social-icon social__icon icon-twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/johns.mustang" target="_blank" rel="noreferrer">
                <i className="footer__social-icon social__icon icon-instagram" />
              </a>
            </li>
            <li>
              <a href="https://facebook.com/johnsmustang" target="_blank" rel="noreferrer">
                <i className="footer__social-icon social__icon icon-facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell large-12">
          <small className="footer__small">&copy; John&apos;s Mustang</small>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
