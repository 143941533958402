/**
 * @file Dashboard Page
 */

import React from 'react';
// import Backorders from '../components/Backorders';
import IntegrationLinks from '../components/IntegrationLinks';
import Layout from '../components/Layout';
// import Orders from '../components/Orders';

const DashboardPage = () => (
  <Layout>

    {/**
    <div className="grid-x grid-padding-x margin-bottom-50">
      <div className="cell small-6">
        <div className="callout primary">
          <div className="callout-image" style={{ backgroundImage: `url(https://placehold.it/800x800?text=chart)`, height: 400, width: 400 }}></div>
        </div>
      </div>
      <div className="cell small-6">
        <div className="callout primary">
          <div className="callout-image" style={{ backgroundImage: `url(https://placehold.it/800x800?text=chart)`, height: 400, width: 400 }}></div>
        </div>
      </div>
    </div>
    */}

    {/**
    }<div className="grid-x grid-padding-x margin-bottom-50">
      <div className="cell small-12">
        <Orders />
      </div>
    </div>

    <div className="grid-x grid-padding-x margin-bottom-50">
      <div className="cell small-12">
        <Backorders />
      </div>
    </div>
    */}

    <IntegrationLinks />

  </Layout>
);

export default DashboardPage;
