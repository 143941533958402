/**
 * @file Logout
 */

import React from 'react';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.identity = props.identity;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    /* eslint no-underscore-dangle: ["error", { "allow": ["_goTrueInstance"] }] */
    const user = this.identity._goTrueInstance.currentUser();

    user.logout().then(
      window.location.href = '/',
      // console.error('Failed to logout user: %o', error),
    );
  }

  render() {
    return (
      <button
        type="button"
        className="button button--alert button--small"
        onClick={this.handleClick}
      >
        Logout
      </button>
    );
  }
}

export default Logout;
