/**
 * @file Search Box
 */

import React from 'react';

// import { navigate } from 'gatsby';

class SearchBox extends React.Component {
  constructor() {
    super();
    this.autocompleteSelected = this.autocompleteSelected.bind(this);
  }

  componentDidMount() {
    window.addEventListener(
      'autocomplete:selected',
      this.autocompleteSelected,
      true,
    );

    if (window.docsearch) {
      window.docsearch({
        apiKey: process.env.DOCSEARCH_API_KEY,
        indexName: process.env.DOCSEARCH_INDEX,
        inputSelector: '#docs-search',
        algoliaOptions: {
          hitsPerPage: 5,
        },
        debug: true,
      });
    }
  }

  // autocompleteSelected() {
  //   const a = document.createElement('a');
  //
  //   a.href = e.args[0].url;
  //
  //   navigate(`${a.pathname}${a.hash}`);
  // }

  render() {
    return (
      <div className="search-box">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            id="docs-search"
            type="search"
            placeholder="Search..."
          />
        </form>
      </div>
    );
  }
}

export default SearchBox;
