/**
 * @file Style Guide Page
 */

import React from 'react';
import Layout from '../components/Layout';

const StyleGuidePage = () => (
  <Layout>
    <div className="grid-x grid-padding-x margin-bottom-40">
      <div className="cell large-12">
        <h1 className="heading heading--underline-large">Style Guide</h1>
      </div>
    </div>
  </Layout>
);

export default StyleGuidePage;
